@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500;700&display=swap");

$color-primary: #4830d4;
$color-secondary: #f9f9f9;

body {
  font-family: "Readex Pro", sans-serif;
}

p {
  line-height: 1.35rem;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: unset;
  transition: all 0.3s;
}
a:visited {
  color: unset;
}
a:hover {
  color: unset;
  text-decoration: underline;
}

header {
  background: linear-gradient(180deg, $color-secondary 60%, $color-primary 40%);
  color: black;
}

button.btn-text {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  color: $color-secondary;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75;
  padding: 0.312rem 0.937rem;
  border: 0;
}

.language-selector {
  position: absolute;
  color: black;
  right: 0.5rem;
  top: 0.5rem;
  button {
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
  }
  button.active {
    text-decoration: underline;
  }
  span {
    margin: 0 0.25rem;
    cursor: default;
  }
}

nav {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  font-size: 1.25rem;

  .active {
    text-decoration: underline;
  }

  a:not(:last-child) {
    margin-right: 1rem;
  }
}

.nav-items {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: black;
  a {
    font-size: 1.125rem;
  }
}

section {
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.link-text {
  text-decoration: underline;
  color: $color-primary !important;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 2.5rem;
    color: $color-primary;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.5rem;
    color: $color-primary;
    margin-bottom: 1rem;
  }
}

.content-img {
  margin-right: 1.5rem;
  img {
    width: 100%;
    box-sizing: border-box;
    border: 3px solid $color-primary;
    border-radius: 1rem;
  }
}

.grow {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    .show-hover {
      opacity: 1;
    }
  }
}

.skillContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  &.three {
    grid-template-columns: repeat(3, 1fr);
    .skill {
      img {
        max-width: 5rem;
      }
    }
  }
  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 30px rgb(125 127 137 / 15%);
    border-radius: 0.312rem;

    img {
      max-width: 5rem;
      width: 100%;
      flex-grow: 1;
      padding: 0.5rem;
      box-sizing: border-box;
    }
    span {
      width: 100%;
      text-align: center;
      margin-top: 0.75rem;
      font-size: 0.9rem;
      background-color: $color-primary;
      color: white;
      padding: 0.25rem;
      box-sizing: border-box;
    }
  }
}

.content {
  width: 100%;
  margin-bottom: 1rem;
  h2 {
    &:after {
      width: 2.5rem;
      margin-top: 0.5rem;
      display: block;
      content: "";
      height: 0.187rem;
      background: $color-primary;
    }
  }
  .content-half {
    width: 100%;
  }
}
.content-logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 3rem;
  img {
    max-width: 10rem;
  }
}

ul {
  margin: 0;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.logo {
  width: 6rem;
  &.no-banner {
    filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 0.75rem;
  box-sizing: border-box;
}

.profile-img {
  width: 25rem;
  max-width: 100%;
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 9%);
  border-radius: 999rem;
}

.profile-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.banner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3rem;
  h1 {
    font-size: 3rem;
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
  }
}

.social-links {
  display: flex;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    border: 2px solid black;
    border-radius: 99rem;
    transition: all 0.3s;
    & svg {
      transition: all 0.3s;
      color: black;
    }

    &:hover {
      border-color: $color-primary;

      & svg {
        color: $color-primary;
      }
    }
  }

  a:not(:last-child) {
    margin-right: 1rem;
  }
}

footer {
  min-height: 1rem;
  padding: 1rem 0;
  background-color: $color-primary;
  color: $color-secondary;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.snackbar {
  position: fixed;
  color: $color-secondary;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  bottom: 0.75rem;
  left: 1.5rem;
  z-index: 1400;
  display: flex;
  align-items: center;
  background-color: rgb(56, 142, 60);
  border: 0;
  padding: 0 0 0 1rem;
  border-radius: 0.25rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
  svg:not(.close) {
    margin-right: 0.75rem;
  }
  .close {
    margin-left: 2.5rem;
    cursor: pointer;
    padding: 1rem;
  }
  &.open {
    opacity: 1;
  }
}
.hide-mobile {
  display: none;
}

// Mobile
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
// Tablet
@media (min-width: 768px) {
  .skillContainer {
    grid-template-columns: repeat(4, 1fr);
  }
  .nav-items {
    justify-content: flex-end;
    a {
      font-size: 1.25rem;
    }
  }
  .skillContainer.three .skill img {
    max-width: 8rem;
  }

  .container {
    max-width: 768px;
  }
  section {
    flex-direction: row;
  }

  .content {
    width: 100%;
  }
  .content-logo {
    width: 50%;
  }
}
// Laptop
@media (min-width: 1024px) {
  .content-wrapper {
    flex-direction: row;
  }
  .content-half {
    width: 40%;
    &:not(:last-child) {
      width: 60%;
      margin-right: 3rem;
    }
  }
  .logo {
    &.no-banner {
      filter: none;
    }
  }
  .content-img {
    flex-grow: 0.45;
  }
  .language-selector {
    color: $color-secondary;
  }
  .nav {
    a:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
  .hide-mobile {
    display: flex;
  }
  .banner {
    flex-direction: row;
  }
  .profile-info {
    flex-grow: 0.55;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .nav-items {
    color: $color-secondary;
  }
  header {
    background: linear-gradient(
      90deg,
      $color-secondary 60%,
      $color-primary 40%
    );
  }
  .container {
    max-width: 1024px;
    padding: 0;
  }
  .content {
    flex-grow: 0.55;
    max-width: 55%;
  }
}
// Laptop L
@media (min-width: 1300px) {
  .container {
    max-width: 1280px;
  }

  .profile-info {
    flex-grow: 0.45;
  }
}
// Monitor
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
  section {
    margin: 5rem 0;
  }
  .profile-info {
    flex-grow: 0.55;
  }
}
